










































































































































































































































































































































































































































































































































































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import lottie from 'lottie-web';
import VueI18n from 'vue-i18n';
import LocaleMessages = VueI18n.LocaleMessages;

import { hexToPercent } from '@/helpers/colors';
import {
  createCreative,
  getCreative,
  getTemplates,
  getTemplatesVariants,
  getTemplateVariant
} from '@/helpers/api';
import CrossSVG from '@/assets/img/close.svg';
import { FileHandler } from '@/store/state';
import { TemplateVariant, Template, Field } from '@/types/template';

import UploadSVG from '@/assets/img/upload.svg';
import UnsplashSVG from '@/assets/img/unsplash.svg';
import orientationSVG from '@/assets/img/orientation.svg';
import eyeSVG from '@/assets/img/eye.svg';
import editSVG from '@/assets/img/edit.svg';
import MobileBar from '@/components/MobileBar.vue';
import ProgressBar from '@/components/ProgressBar.vue';

import { handleCroppedImage, loadFields } from '@/helpers/lottie';
import { updateSession } from '@/helpers/session';

import analytics from '@/analytics';

@Component({
  components: {
    MobileBar,
    CrossSVG,
    UploadSVG,
    UnsplashSVG,
    orientationSVG,
    editSVG,
    eyeSVG,
    ProgressBar
  }
})
export default class CreativeStudio extends Vue {
  @Prop({}) data!: any;
  private inStreet = false;
  private hasPickedTemplate = false;
  private templatesOpen = false;
  private debounceHandler: any = null;
  private debounceUpdateValue = 300;
  private currentSelectedTemplate: null | Template = null;
  private orientation = 'portrait';
  private templates: Array<Template> = [];
  private portraitVariant: TemplateVariant | null = null;
  private landscapeVariant: TemplateVariant | null = null;
  private progress = 0;
  private anim: any = null;
  private percentage = 0;
  private source: any = null;
  private completed = false;
  private scrubbing = {
    active: false,
    wasPaused: undefined
  };
  private currentMobileView: 'customise' | 'preview' = 'preview';
  private mediaError: string | LocaleMessages | null = null;
  private animationDuration = 0;
  private animationInterval = false;

  private get mediaHandler(): FileHandler | null {
    return this.$store.state.media;
  }

  private maxFile = 51200000; // 50Mb
  private acceptedTypes = ['video/mp4', 'video/x-msvideo', 'video/quicktime', 'video/webm'];

  @Watch('inStreet')
  private refreshPreview() {
    this.play();
    // Needed to remove bug display first time
    setTimeout(() => this.play(), 50);
  }
  private changeHasPickedTemplate() {
    analytics.track('selectTemplate', {
      id: this.currentSelectedTemplate?.id
    });
    this.hasPickedTemplate = true;
    setTimeout(() => this.play(), 50);
  }
  private uploadFile(e: any) {
    if (!e.target.value) return;
    this.mediaError = null;
    const file = e.target.files[0];

    if (file.size > this.maxFile) {
      e.target.value = null;
      this.mediaError = this.$t('creative.file_too_big');
      return;
    }
    if (!this.acceptedTypes.includes(file.type)) {
      e.target.value = null;
      this.mediaError = this.$t('creative.file_type_not_accepted');
      return;
    }
    analytics.track('uploadVideo', {
      source: URL.createObjectURL(file),
      size: file.size,
      name: file.name,
      type: file.type
    });

    this.$store.commit('setMedia', {
      source: URL.createObjectURL(file),
      name: file.name,
      type: file.type
    });

    const CancelToken = axios.CancelToken;
    this.$store.state.cancelTokenSource = CancelToken.source();

    const config = {
      onUploadProgress: (progressEvent: any) => {
        this.$store.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
      cancelToken: this.$store.state.cancelTokenSource.token
    };
    const data = new FormData();
    data.append('title', file.name);
    data.append('file', file);
    e.target.value = null;
    axios
      .post('https://api.staging.vx.glooh.app/files', data, config)
      .then(() => {
        this.$store.state.progress = 0;
      })
      .catch(err => {
        this.$store.commit('setMedia', null);
        this.mediaError = this.$t('An error occurred during the upload');
        Sentry.captureException(e);
      });
  }

  private stopUpload() {
    this.$store.state.cancelTokenSource.cancel('Upload canceled');
    this.$store.state.progress = 0;
    this.$store.commit('removeMedia');
  }

  private animationsDuration() {
    this.animationDuration = 0;
    if (!this.animationInterval)
      setInterval(() => {
        this.animationInterval = true;
        if (this.animationDuration < 100) {
          this.animationDuration++;
        } else {
          this.animationDuration = 0;
        }
      }, 100);
  }

  private pause(e: any) {
    if (!this.scrubbing.active) {
      this.scrubbing.active = true;
      this.scrubbing.wasPaused = this.anim.isPaused;

      this.anim.pause();
      this.update(e);
    }
  }

  private update(e: any) {
    if (this.scrubbing.active) {
      //@ts-ignore
      const bounds = this.$refs.track.getBoundingClientRect();
      const offsetX = e.clientX - bounds.left,
        decPercent = offsetX / bounds.width;
      if (offsetX >= 0 && offsetX <= bounds.width) {
        this.percentage = decPercent * 100;
        this.anim.goToAndStop(decPercent * this.anim.totalFrames, true);
      }
    }
  }

  private getTime(time: number) {
    time = Math.round(time);
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes}:${seconds <= 9 ? '0' + seconds : seconds}`;
  }

  private play() {
    lottie.stop();
    lottie.destroy();
    if (this.$store.state.template) {
      this.anim = lottie.loadAnimation({
        container: this.inStreet
          ? (this.$refs.previewStreet as Element)
          : (this.$refs.preview as Element),
        renderer: 'svg',
        animationData: this.$store.state.template.json_data,
        loop: true,
        autoplay: true
      });
      lottie.play();
    }
  }
  private changeCurrentMobileView() {
    this.currentMobileView = 'preview';
    setTimeout(() => this.play(), 50);
  }
  private toggleTemplate(template: Template) {
    this.hasPickedTemplate = false;
    if (!this.currentSelectedTemplate) this.currentSelectedTemplate = template;
    else {
      this.currentSelectedTemplate =
        this.currentSelectedTemplate.id === template.id ? null : template;
    }
  }
  private async loadTemplate(template: Template) {
    try {
      this.toggleTemplate(template);
      if (this.currentSelectedTemplate) {
        const variants: Array<TemplateVariant> = await getTemplatesVariants(template.id);
        this.portraitVariant = null;
        this.landscapeVariant = null;
        variants.forEach(variant => {
          if (variant.orientation === 'portrait') this.portraitVariant = variant;
          if (variant.orientation === 'landscape') this.landscapeVariant = variant;
        });
        if (!this.portraitVariant && this.landscapeVariant) this.orientation = 'landscape';
        if (this.portraitVariant) this.orientation = 'portrait';
        this.currentSelectedTemplate.fields.forEach((field: Field, index: number) => {
          if (field.type === 'textarea') {
            this.currentSelectedTemplate!.fields[index].params.text = field.params.text
              .split('\n')
              .join('\r');
          }
        });
        this.$store.commit(
          'setTemplate',
          this.portraitVariant ? this.portraitVariant : this.landscapeVariant
        );
        this.$store.commit('setRootTemplate', this.currentSelectedTemplate);
        this.$store.commit(
          'setTemplateFields',
          loadFields(this.currentSelectedTemplate, this.$store.state.template)
        );
        this.play();
        // Mandatory to be able to remove bug when displayed for the first time
        setTimeout(() => this.play(), 50);
      } else {
        this.$store.commit('setTemplate', null);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
    this.play();
  }
  private updateValue(f: any, e: Event) {
    const value = (e.target as HTMLInputElement).value;
    f.value = value;
    f.params = { ...f.params, text: value };
    if (Array.isArray(f.target)) {
      f.target = f.target.map((target: any) => {
        target.t.d.k[0].s.t = value.split('\n').join('\r');
        return target;
      });
    } else if (f && f.target) {
      f.target.t.d.k[0].s.t = value.split('\n').join('\r');
    }
    clearTimeout(this.debounceHandler);
    this.debounceHandler = setTimeout(() => {
      this.play();
    }, this.debounceUpdateValue);
  }

  private changeColor(f: any, e: Event) {
    const value = (e.target as HTMLInputElement).value;
    f.value = value;
    f.params._hex = value;
    f.params.value = hexToPercent(value);
    if (Array.isArray(f.target)) {
      f.target = f.target.map((target: any) => {
        if (target.t) target.t.d.k[0].s.fc = f.params.value;
        if (target.sc) target.sc = f.params._hex;
        if (target.shapes) target.shapes[0].it[1].c.k = f.params.value;
        return target;
      });
    }
    clearTimeout(this.debounceHandler);
    this.debounceHandler = setTimeout(() => {
      this.play();
      this.$forceUpdate();
    }, this.debounceUpdateValue);
  }

  private toggleOrientation(orientation: 'landscape' | 'portrait') {
    this.orientation = orientation;
    this.$store.commit(
      'setTemplate',
      this.orientation === 'portrait' ? this.portraitVariant : this.landscapeVariant
    );
    loadFields(this.$store.state.rootTemplate, this.$store.state.template);
    this.play();
  }

  private clickUploadMedia() {
    if (this.currentSelectedTemplate) {
      this.$modal.load({
        template: 'Prompt',
        data: {
          content: this.$t('creative.if_upload_remove')
        },
        confirmButton: {
          text: this.$t('creative.proceed')
        },
        onConfirm: () => {
          //@ts-ignore
          this.$refs.uploadMedia.click();
          this.$modal.close();
        }
      });
    } else {
      //@ts-ignore
      this.$refs.uploadMedia.click();
    }
  }

  private generateThumbnailURL(id: string) {
    return 'https://api.staging.vx.glooh.app/assets/' + id + '?key=preview-image';
  }

  private changeImage(field: Field, event: any, index: number) {
    if (!event.target.files.length) return;
    const tempField = {
      ...field.asset,
      p: window.URL.createObjectURL(event.target.files[0]),
      u: '',
      tempName: event.target.files[0].name
    };
    const ratio = field.params && field.params.ratio ? field.params.ratio : 0.75;
    this.$modal.load({
      template: 'Cropper',
      data: {
        img: tempField.p,
        ratio
      },
      confirmButton: {
        text: this.$t('_globals.confirm'),
        classes: { 'button-classic': true }
      },
      onConfirm: (img: string) => {
        handleCroppedImage(img, index, () => {
          this.$forceUpdate();
          this.$modal.close();
          this.play();
          event.target.value = '';
        });
      }
    });
  }

  private async confirm() {
    const creative = await createCreative({
      template_variant: this.$store.state.template.id,
      is_draft: false,
      fields: this.$store.state.templateFields.map((f: any) => {
        const temp = { ...f };
        delete temp.target;
        return temp;
      })
    });
    this.$store.commit('setCreative', creative.data.id);
    updateSession({
      booking: {
        panels: this.$store.state.screens,
        creativeId: creative.data.id,
        dates: { start: this.$store.state.fromDate, end: this.$store.state.toDate }
      }
    });
    this.$router.push({ name: 'checkout' });
  }

  @Watch('anim.currentFrame')
  animation() {
    this.percentage = (this.anim.currentFrame / this.anim.totalFrames) * 100;
  }

  private resume() {
    if (this.scrubbing.active) {
      if (!this.scrubbing.wasPaused) {
        this.anim.play();
      }
      this.scrubbing.active = false;
    }
  }

  private async created() {
    updateSession({
      currentPage: this.$route.name
    });
    if (this.$store.state.creative) {
      const creative = await getCreative(this.$store.state.creative);
      const template_variant = await getTemplateVariant(creative[0].template_variant);
      const templates = await getTemplates();
      let template = templates.find((t: any) => t.id === template_variant[0].template_id);
      template = {
        ...template,
        fields: creative[0].fields
      };
      this.loadTemplate(template);
      this.hasPickedTemplate = true;
    }
  }

  private async mounted() {
    this.templates = await getTemplates();
    if (this.$store.state.config?.creativeStudio?.defaultOrientation)
      this.orientation = this.$store.state.config.creativeStudio.defaultOrientation;
    if (this.$store.state.template) {
      this.orientation = this.$store.state.template.orientation;
      this.currentSelectedTemplate = this.$store.state.template;
      this.play();
    }
    document.addEventListener('mousemove', this.update);
    document.addEventListener('mouseup', this.resume);
  }

  private beforeDestroy() {
    document.removeEventListener('mousemove', this.update);
    document.removeEventListener('mouseup', this.resume);
  }
}
